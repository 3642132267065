<template>
	<div
		class="wrap__content"
		v-bind:class="{
			'wrap__content--preloader': !applications || !_getRegionList || !$store.state.userObject,
		}"
	>
		<v-preloader
			v-if="!applications || !_getRegionList || !$store.state.userObject"
			:message="errorMessage"
		></v-preloader>

		<div class="container" v-if="applications && _getRegionList && $store.state.userObject">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['FishBookManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__page--back">
										<a @click="$router.go(-1)">
											<svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.125 5.25l-5.25 5.25 5.25 5.25" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
										</a>
									</div>
									<div class="content__title--element">
										<div class="content__title--text">Просмотр книги рыб</div>
										<div class="content__title--subtext">
											{{ applications.name }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<form method="POST" enctype="multipart/form-data" @submit.prevent="onSubmit">
									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Название</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<input
															type="text"
															class="input-linck"
															required=""
															name="name"
															:value="applications.name"
															disabled
															:placeholder="$t('headers.name')"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Регион</div>
										</div>
										<div class="form__line--right">
											<div class="row">
												<div class="col-xl-5">
													<div class="form__line--input">
														<input
															type="text"
															class="input-linck"
															required=""
															name="name"
															:value="formList.region"
															disabled
															placeholder="Регион"
														/>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="form__line">
										<div class="form__line--left">
											<div class="form__line--title">Файлы</div>
										</div>
										<div class="form__line--right">
											<div
												class="form__line form__line--children"
												v-for="(item, index) in filedsList"
												:key="index"
											>
												<div class="row align-items-center">
													<div class="col-xl-5">
														<div class="form__line--input form__block--input">
															<a
																:href="urlApi + item.file"
																class="form__line--file form__line--link"
																target="_blank"
																download="download"
															>
																<div class="form__file form__file--center">
																	<div class="form__file--icon">
																		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M20 16.2A4.5 4.5 0 0017.5 8h-1.8A7 7 0 104 14.9M12 12v9M8 17l4 4 4-4" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
																	</div>
																	<div class="form__file--text">Скачать файл</div>
																</div>
															</a>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

import { urlApi, api } from '@/boot/axios'

export default {
	data() {
		return {
			urlApi: urlApi,
			errorMessage: {
				status: null,
				messages: null,
				text: null,
			},
			message: {
				status: null,
				text: null,
			},

			applications: null,
			regionValue: null,

			filedsList: [],
			filedsIndex: 0,
		}
	},
	computed: {
		...mapGetters(['_getRegionList']),
		formList() {
			let region = this._getRegionList.find((element) => {
				if (this.applications.region_id == element.id) return element
			})

			this.applications.items.find((element) => {
				this.filedsList.push({
					file: element.document,
					fileName: element.document,
					fileClass: 'add_tourist--success',
				})
			})

			let userRegions = []
			this.$store.state.userObject.regions.find((element) => {
				userRegions.push(element.region_id)
			})

			let regions = []
			regions = this._getRegionList.filter((element) => {
				if (userRegions.includes(element.id)) {
					return true
				}
			})

			return {
				regions: regions,
				region: region.name,
			}
		},
	},
	methods: {
		...mapActions(['_fetchRegionList']),
		onSubmit(evt) {
			this.errorMessage = {
				status: null,
				messages: null,
				text: null,
			}
			this.message = {
				status: null,
				text: null,
			}
			const formData = new FormData(evt.target)

			this.filedsList.find((element, index) => {
				formData.append('documents[' + index + '][file]', element.file)
			})

			formData.append('region_id', this.regionValue.id)

			api
				.post('fish-book/create', formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					if (response.status == 200) {
						this.message.status = 200
						this.message.text = response.data.message

						this.$router.push({ path: '/' + this.$i18n.locale + '/account/fish-book' })
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					this.errorMessage.messages = error.response.data.error_messages
				})
		},

		apiGetBook() {
			api
				.get('fish-book/' + this.$route.params.id)
				.then((response) => {
					if (response.data) {
						this.applications = response.data
					}
				})
				.catch((error) => {
					if (error.response) {
						if (error?.response?.status == 500) {
							this.errorMessage.status = 500
							this.errorMessage.text = this.$t('system_message.500')
						}
						if (error?.response?.status == 401) {
							this.errorMessage.status = 401
							this.errorMessage.text = error.response.data.error_message
							this.$router.push('/' + this.$i18n.locale + '/login')
						}
						if (error?.response?.status == 422) {
							this.errorMessage.status = 422
							this.errorMessage.text = error.response.data.error_message
						}
						if (error?.response?.status == 403) {
							this.errorMessage.status = 403
							this.errorMessage.text = error?.response?.data?.message
						}
					}
				})
		},
	},
	beforeMount() {
		this._fetchRegionList()
		this.apiGetBook()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
}
</script>

<style scoped></style>
